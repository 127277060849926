<template>
  <div class="content-edit">
    <el-input v-model="content" :size="controlSize" placeholder="备注"></el-input>
    <div class="btn-group">
      <el-button type="danger" :size="controlSize" @click="save">确定</el-button>
      <el-button type="info" :size="controlSize" @click="$emit('close')">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    layerInfo: Object
  },

  data() {
    return {
      controlSize: "mini",
      content: ""
    }
  },

  mounted() {
    this.content = this.layerInfo.content || ""
  },

  methods: {
    save() {
      if (this.content === this.layerInfo.content) {
        this.$emit("close")
        return false;
      }
      this.$emit("handle", {...this.layerInfo, content: this.content})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "content-edit";
</style>
